import React, { ReactNode } from 'react';
import { IThreeSixtyAnimationProps, IThreeSixtyAnimationState } from './threeSixtyAnimation.d';
import IconZoomOut from '../../Assets/svg/icon_zoom_out';
import IconZoomIn from '../../Assets/svg/icon_zoom_in';
import * as helper from '../common/scs-html-helper';
import './threeSixtyAnimation.scss';
import { SCSScrollLock } from '../common/scs-scrollLock';
import LoadingSpinner from '../common/loadingSpinner/loadingSpinner';
import IconPlayStop from '../../Assets/svg/icon_play_stop';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

import {ImageController} from '../common/imageController';

const minZoomLevel = 0;
const maxZoomLevel = 2;

export default class ThreeSixtyAnimation extends React.Component<IThreeSixtyAnimationProps,
    IThreeSixtyAnimationState> {
    
    private animationTimer: ReturnType<typeof setTimeout>;
    private windowResizeTimer: ReturnType<typeof setTimeout>;
    private animationRef = React.createRef<HTMLDivElement>();
    private threeDImagesRef = React.createRef<HTMLDivElement>();
    private threeDOverlay: HTMLElement;
    private imageLoadedProgress: number;
    private scrollLock: SCSScrollLock;
    private reactZoomPanPinchRef: ReactZoomPanPinchRef;
    private offset = 0;

    private imageController: ImageController;

    // image move feature:
    private gMouseDownX = 0;
    private gMouseDownY = 0;
    private gMouseDownOffsetX = 0;
    private gMouseDownOffsetY = 0;
    private activeImageContainer: HTMLElement;
    //--
        
    constructor(props) {
        super(props);

        this.state = {
            dragging: false,
            imageIndex: 1,
            animationSequenceIndex: 1, // mostly used in pf component slider
            dragStartImageIndex: 0,
            playAnimation: true,
            dragStartScreenX: 0,
            animationIsLoaded: false,
            currentImageHeight: 0,
            zoomInLevel: minZoomLevel,
            imageMovementIsEnabled: false,
            loadContentAfterPageLoad: this.props.loadContentAfterPageLoad,
            zoomedImageIndex: null,
            zoomedImageHeight: null,
            loadAnimation: false, // prevents image load during component render time
            displayThreeSixtyOverlay: false, // needed for kiosk pc
            imageHeight: null
        };

        this.imageLoadedProgress = 0;
        this.imageController = ImageController.instance;

        // Init events:
        this.handleRotationByHand = this.handleRotationByHand.bind(this);
        this.handleRotationByTouch = this.handleRotationByTouch.bind(this);
        this.handleTouchend = this.handleTouchend.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleTouchDown = this.handleTouchDown.bind(this);
        this.preventDragHandler = this.preventDragHandler.bind(this);
        this.handleStartStopClick = this.handleStartStopClick.bind(this);        
        this.restartAnimation = this.restartAnimation.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleZoomInClick = this.handleZoomInClick.bind(this);
        this.handleZoomOutClick = this.handleZoomOutClick.bind(this);
        this.openThreeSixtyOverlay = this.openThreeSixtyOverlay.bind(this);
        
        this.onPinchingStart = this.onPinchingStart.bind(this);
        this.onPinchingStop = this.onPinchingStop.bind(this);
        this.handleOnInit = this.handleOnInit.bind(this);
        this.onEnterViewport = this.onEnterViewport.bind(this);

        // image zoom feature:
        if(this.props.showOverlayAnimation) {
            this.updateZoomedImagePosition = this.updateZoomedImagePosition.bind(this);
            this.updateZoomedImagePositionTouch = this.updateZoomedImagePositionTouch.bind(this);
        }
        //--
    }

    componentDidMount() {
        document.addEventListener('mousemove', this.handleRotationByHand, false);
        document.addEventListener('mouseup', this.handleTouchend, false);
        window.addEventListener('resize', this.handleWindowResize, false);
        this.threeDOverlay = document.getElementById(this.props.containerId);

        if (this.props.showOverlayAnimation && this.threeDOverlay) {
            window.shell.subscribeTo('SCS.Open.Gallery3DView',
                (displayG3dOverlay: boolean) => {
                    if (displayG3dOverlay)
                        this.openThreeSixtyOverlay();
                },
                'SCS.Gallery3DView');
        } else {
            document.addEventListener('scroll', this.onEnterViewport, true);
        }

        if(window.shell) {
            window.shell.subscribeTo('SCS.Show.ThreeSixtyView',
            (payload) => {
                this.handleShowThreeSixtyViewEvent(payload.containerId, payload.showThreeDView);
            },
            this.props.containerId + '.ShowThreeDView');

        window.shell.subscribeTo('SCS.PlayPauseAnimation',
            (payload) => {
                if (this.props.containerId === payload.containerId) {
                    this.playStopAnimation(payload.play3d);
                }
            },
            this.props.containerId + '.ShowThreeDView');
        }        
    }

    componentWillUnmount() {
        // No longer need to keep endless loop if component is unmounted:
        // clear previous timeout:
        clearTimeout(this.animationTimer);
        this.setState({ playAnimation: false });

        document.removeEventListener('mousemove', this.handleRotationByHand, false);
        document.removeEventListener('mouseup', this.handleTouchend, false);
        window.removeEventListener('resize', this.handleWindowResize);

        if (this.props.showOverlayAnimation && this.threeDOverlay)
            window.shell.unsubscribeFrom('SCS.Open.Gallery3DView', 'SCS.Gallery3DView');

        window.shell.unsubscribeFrom('SCS.Show.ThreeSixtyView', 
            this.props.containerId + '.ShowThreeDView');
        window.shell.unsubscribeFrom('SCS.PlayPauseAnimation', 
            this.props.containerId + '.ShowThreeDView');
    }

    public handleShowThreeSixtyViewEvent(containerId: string, showThreeDView: boolean): void {
        if (this.props.containerId === containerId) {
            this.setState({
                loadContentAfterPageLoad: showThreeDView,
                loadAnimation: true
            });
        }
    }

    private setImageSize(): void {
        const newImageHeight: number = this.imageController.getImageHeight(
            this.props.generalData);
        this.setState({imageHeight: newImageHeight});
    }

    public initAnimation(): void {
        this.setImageSize();

        // handling regular 3d animation:
        if (this.state.loadContentAfterPageLoad && !helper.isMobileShop()) {
            this.setState({loadAnimation: true});
        } else if (this.state.loadContentAfterPageLoad && this.props.loadContentAfterPageLoadMobile 
            && helper.isMobileShop()) {
                // only for specific cases when we need to show 3d on mobile at once: 
                // (additional description in PDP)
                this.setState({loadAnimation: true});
        }

        this.onEnterViewport();
    }

    public handleWindowResize(): void {
        // clear previous timeout:
        clearTimeout(this.windowResizeTimer);

        this.windowResizeTimer = setTimeout(() => {
            this.setImageSize();
        }, 500);


        if(this.props.showOverlayAnimation 
            && this.threeDOverlay && this.state.loadContentAfterPageLoad ) {
            if (!helper.isMobileShop() && !helper.isTabletView())
                // handling gallery overlay:
                this.openThreeSixtyOverlay();
        }
        else {
            // resize simple 360 animation in details page view (not mobile or tablet)
            this.initAnimation();
        }
    }

    public openThreeSixtyOverlay(): void {
        if(!this.state.loadContentAfterPageLoad) {
            this.setState({
                loadContentAfterPageLoad: true,
                loadAnimation: true,
                displayThreeSixtyOverlay: true
            }, () => {
                this.setOverlayViewWindowSize();

                if(helper.isTabletView() || helper.isMobileShop()) {
                    this.scrollLock = SCSScrollLock.instance;
                    this.threeDImagesRef.current.addEventListener('touchstart', this.handleTouchDown, false);
                    this.threeDImagesRef.current.addEventListener('touchend', this.handleTouchend, false);
                    this.threeDImagesRef.current.addEventListener('touchmove', this.handleRotationByTouch, false);
                }
            });
        }
        else {
            this.setOverlayViewWindowSize();
        }
    }

    private setOverlayViewWindowSize(): void {
        let new_height = 900; // default value is needed only for tests
        if (this.threeDOverlay)
            new_height = this.threeDOverlay.clientHeight > 900
                ? 900 : this.threeDOverlay.clientHeight;

        // only desktop devices has controls
        if (this.props.showOverlayAnimation && !helper.isMobileShop()) {
            new_height = new_height - 130 - 70; // remove zoom on/out buttons size - start/stop and close buttons
        }

        // clientHeight - threeSixtyThumbnails:
        if (this.props.hasThreeSixtyThumbnails && !helper.isDeskTopView()) {
            new_height = new_height - 130;
        }

        this.setThreeDImagesContainerDimensions(new_height);

        this.playStopAnimation(true);
    }

    private setThreeDImagesContainerDimensions(height: number): void {
        this.setState({            
            currentImageHeight: height,
            loadAnimation: true
        }, () => {
            // If height is greater then max value, use maximum allowed height:
            if (this.animationRef.current.clientHeight > 0
                && this.animationRef.current.clientHeight > height) {
                this.threeDImagesRef.current.style.height = height + 'px';
            } else {
                this.threeDImagesRef.current.style.height = this.state.currentImageHeight + 'px';
            }
        });
    }

    private playAnimation(index: number, animationSequenceIndex: number): void {
        if (this.state.playAnimation && this.props.animationSequence.length > 1) {
            if (index === this.props.animationSequence.length) {
                index = 0; // reset rotation
                animationSequenceIndex = 0; // reset slider
            }

            // set next 3D image index:
            this.setState({ imageIndex: this.props.animationSequence[index].imageIndex });

             // provide imag image index to observer component:
            if(this.props.updateSliderIndex)
               this.props.updateSliderIndex(animationSequenceIndex);

            // clear previous timeout:
            clearTimeout(this.animationTimer);

            this.animationTimer = setTimeout(() => {
                this.playAnimation(++index, ++animationSequenceIndex);
            }, this.props.animationSpeed);
        }
    }

    private handleRotationByTouch(event: TouchEvent): void  {
        if (this.state.dragging && !this.state.imageMovementIsEnabled) {
            this.updateImageIndex(event.touches[0].screenX);
        }
    }

    private handleRotationByHand(event: MouseEvent): void  {
        if (this.state.dragging && !this.state.imageMovementIsEnabled) {
            this.updateImageIndex(event.screenX);
        }
    }

    private updateImageIndex(currentPositionX: number): void {
        const index = this.imageController.getRotatedImageIndex(currentPositionX,
            this.props.animationSequence.length, this.state.dragStartScreenX,
            this.state.dragStartImageIndex);

        if (index !== this.state.imageIndex) {
            this.setState({ imageIndex: this.props.animationSequence[index].imageIndex });
        }
    }

    private handleTouchend(): void  {    
        if(!this.state.imageMovementIsEnabled)
            this.setState({ dragging: false });
        else {
            // image move feature:
            window.removeEventListener('mousemove', this.updateZoomedImagePosition, true);
            window.removeEventListener('touchmove', this.updateZoomedImagePositionTouch, true);

            if(helper.isTabletView())
                this.scrollLock.unlock();
            //--
        }
    }

    private handleTouchDown(event: TouchEvent): void {
        if(this.state.imageMovementIsEnabled) {
            this.scrollLock.lock();
            const touch = event.touches[0];
            this.handleImageMovement(touch.clientX, touch.clientY);
        }
        else {
            this.setState(state => ({
                playAnimation: false,
                dragging: true,
                dragStartScreenX: event.touches[0].screenX,
                dragStartImageIndex: state.imageIndex
            }));
        }
    }

    private handleMouseDown(event): void {
        if(this.state.imageMovementIsEnabled) {
            this.handleImageMovement(event.clientX, event.clientY);
        }
        else {
            event.persist();

            this.setState(state => ({
                playAnimation: false,
                dragging: true,
                dragStartScreenX: event.screenX,
                dragStartImageIndex: state.imageIndex,
            }));
        }
    }

    // image move feature:
    private handleImageMovement(clientX: number, clientY: number): void {
        this.gMouseDownX = clientX;
        this.gMouseDownY = clientY;
    
        //The following block gets the X offset (the difference between where it starts and where it was clicked)
        const leftPart = this.activeImageContainer.style.left;
        const leftPos = leftPart.indexOf('px');
        const leftNumString = leftPart.slice(0, leftPos); // Get the X value of the object.

        this.gMouseDownOffsetX = this.gMouseDownX - parseInt(leftNumString, 10);
    
        // The following block gets the existing Y offset 
        // (the difference between where it starts and where it was clicked)
        if(this.activeImageContainer.style.top) {
            const topPart = this.activeImageContainer.style.top;        
        
            const topPos = topPart.indexOf('px');
            const topNumString = topPart.slice(0, topPos); // Get the Y value of the object.        

            this.gMouseDownOffsetY = this.gMouseDownY - parseInt(topNumString, 10);
        }

        window.addEventListener('mousemove', this.updateZoomedImagePosition, true);

        if(helper.isTabletView())
            window.addEventListener('touchmove', this.updateZoomedImagePositionTouch, true);
    }

    private updateZoomedImagePosition(event: MouseEvent): void {
        let topAmount: number;

        // Set top position value for a first time after mouse is down and image move starts:
        if(!this.activeImageContainer.style.top) {
            this.gMouseDownOffsetY = (parseInt(this.threeDImagesRef.current.style.height, 10) / 2) * -1;
            topAmount = this.gMouseDownOffsetY;

            // set value for future image move:
            this.gMouseDownOffsetY = event.clientY - this.gMouseDownOffsetY;
        }
        else {
            topAmount = event.clientY - this.gMouseDownOffsetY;
        }    

        this.activeImageContainer.style.top = topAmount + 'px';
        const leftAmount = event.clientX - this.gMouseDownOffsetX;
        this.activeImageContainer.style.left = leftAmount + 'px';
    }

    private updateZoomedImagePositionTouch(event: TouchEvent): void {
        let topAmount: number;

        // Set top position value for a first time after mouse is down and image move starts:
        if(!this.activeImageContainer.style.top) {
            this.gMouseDownOffsetY = (parseInt(this.threeDImagesRef.current.style.height, 10) / 2) * -1;
            topAmount = this.gMouseDownOffsetY;

            // set value for future image move:
            this.gMouseDownOffsetY = event.touches[0].clientY - this.gMouseDownOffsetY;
        }
        else {
            topAmount = event.touches[0].clientY - this.gMouseDownOffsetY;
        }

        this.activeImageContainer.style.top = topAmount + 'px';
        const leftAmount = event.touches[0].clientX - this.gMouseDownOffsetX;
        this.activeImageContainer.style.left = leftAmount + 'px';
    }
    //--

    private preventDragHandler(event): void {
        event.preventDefault();
    }

    private handleStartStopClick(): void {
        if(this.state.playAnimation) {
            // stop animation:
            this.playStopAnimation(false);
        }
        else {
            // start animation:
            // restore zoomed image state:
            this.resetDefaultImageSize();
            this.playStopAnimation(true);
        }
    }

    private handleZoomInClick(): void {
        this.playStopAnimation(false);

        this.activeImageContainer = this.animationRef.current.querySelector('.active_image');

        // Zoom in limit exceeded:
        if(this.state.zoomInLevel === maxZoomLevel)
            return;

        if(this.state.zoomInLevel === minZoomLevel) {
            this.setState({imageMovementIsEnabled: true});
        }

        const tmpZoomInLevel = this.state.zoomInLevel + 1;

        this.setState({
            zoomInLevel: tmpZoomInLevel,
            zoomedImageHeight: this.state.currentImageHeight + this.state.currentImageHeight * tmpZoomInLevel,
            zoomedImageIndex: parseInt(this.activeImageContainer.dataset.index)
        }, ()=>{
            this.activeImageContainer.style.position = 'absolute';
            this.activeImageContainer.style.width = 'auto';
            this.activeImageContainer.style.height = 'auto';
            this.activeImageContainer.style.maxWidth = 'initial';
            this.activeImageContainer.style.maxHeight = 'initial';
            this.activeImageContainer.style.transform = 'translateX(-50%)';

            if(!this.activeImageContainer.style.left) {
                this.activeImageContainer.style.left = this.threeDImagesRef.current.clientWidth / 2 + 'px';
            }
        });
    }

    private handleZoomOutClick(): void {
        // Zoom in bottom limit exceeded:
        if(this.state.zoomInLevel === minZoomLevel) {
            return;
        }

        const tmpZoomInLevel = this.state.zoomInLevel - 1;

        if(tmpZoomInLevel === minZoomLevel) {
            this.resetDefaultImageSize();
        }
        else {
            this.setState({
                zoomInLevel: tmpZoomInLevel,
                zoomedImageHeight: this.state.zoomedImageHeight - this.state.currentImageHeight * tmpZoomInLevel
            });
        }
    }

    public resetDefaultImageSize(): void {
        if (this.activeImageContainer) {
            this.activeImageContainer.style.removeProperty('position');
            this.activeImageContainer.style.removeProperty('top');
            this.activeImageContainer.style.removeProperty('left');
            this.activeImageContainer.style.removeProperty('transform');
            this.activeImageContainer.style.removeProperty('height');
            this.activeImageContainer.style.removeProperty('width');
            this.activeImageContainer.style.removeProperty('max-height');
            this.activeImageContainer.style.removeProperty('max-width');
        }

        // reset default size:
        this.setState({
            zoomInLevel: minZoomLevel,
            imageMovementIsEnabled: false, // enable rotation by hand
            zoomedImageIndex: null,
            zoomedImageHeight: null,
        });

        if (helper.isMobileShop() && !!this.reactZoomPanPinchRef) {
            this.reactZoomPanPinchRef.resetTransform();
            this.reactZoomPanPinchRef.instance.setup.panning.disabled = true;
        }

        // this.scrollLock can be not yet initialized while 3d overlay was not open, it just not needed yet:
        if((helper.isTabletView() || helper.isMobileShop()) && this.scrollLock) 
            this.scrollLock.unlock();
    }

    private renderElementsForThreeSixtyView(): JSX.Element {
        return (
            this.props.showOverlayAnimation ?
                <div ref={this.threeDImagesRef}
                    className={this.threeDImagesClass()}
                    onMouseDown={this.handleMouseDown}
                    onDragStart={this.preventDragHandler}>
                    {this.getImageElements()}
                </div>
                : <div ref={this.threeDImagesRef}
                    className={this.threeDImagesClass()}>{this.getImageElements()}</div>
        );
    }

    private handleImageLoaded(): void {
        this.imageLoadedProgress++;

        if (this.props.imageUrlArray.length === this.imageLoadedProgress)
        {
            // reset load process counter:
            this.imageLoadedProgress = 0;
            
            this.setState({
                animationIsLoaded: true
            }, ()=> {
                // start/restart animation rotation if only it was not stopped bus user before:
                if (!this.props.preventAutoPlay)
                    this.playStopAnimation(true);

                if(window.shell)
                    window.shell.publishTo('SCS.Animation.WasLoaded', {
                    containerId: this.props.containerId});
            });
        }
    }

    private getAltTag(): string {
        if (!this.props.productInfo)
            return '';
        const categoryName = 
            this.props.productInfo.categoryName ?  ', ' + this.props.productInfo.categoryName : '';
        const designation = 
            this.props.productInfo.designation ? ', ' + this.props.productInfo.designation : '';
        const colorName = 
            this.props.productInfo.colorName ? ', ' + this.props.productInfo.colorName : '';
        return this.props.productInfo.l10n.localizationKey + categoryName + designation + colorName;
    }

    private getImageElements(): ReactNode {
        return this.state.loadAnimation ?
            this.props.imageUrlArray.map((imgUrl, imageIndex) => {
                const newIndex: number = imageIndex + 1;

                return <img src={this.getImageUrl(imgUrl, newIndex)}
                            data-testid={'three_d_image'}
                            className={this.threeDImageClass(newIndex)}
                            data-index={newIndex}
                            key={imageIndex}
                            decoding={'sync'}
                            onLoad={this.handleImageLoaded.bind(this, imageIndex)}
                            onDragStart={this.preventDragHandler}
                            alt={this.getAltTag()}
                        />
            })
            :
            <></>
    }

    private getImageUrl(imgUrl: string, index: number): string {
        let imgHeight = this.state.imageHeight;

        if (this.state.zoomedImageIndex && this.state.zoomedImageIndex === index) {
            imgHeight = this.state.zoomedImageHeight ?? this.state.currentImageHeight;
        }

        return [imgUrl, '&height=', imgHeight].join('');
    }

    public playStopAnimation(play: boolean): void {
        this.setState({ playAnimation: play }, () => {
            if (play)
                this.playAnimation(this.state.imageIndex, this.state.animationSequenceIndex);
        });
    }

    public restartAnimation(playAnimation: boolean): void {
        this.setState({
            dragging: false,
            imageIndex: 1,
            animationSequenceIndex: 1,
            dragStartImageIndex: 0,
            playAnimation: playAnimation,
            dragStartScreenX: 0,
            imageMovementIsEnabled: false
        }, () => {
            // restore zoomed image state:
            this.resetDefaultImageSize();

            // reset load process counter:
            this.imageLoadedProgress = 0;
            this.playStopAnimation(playAnimation);
        });
    }

    private zoomButtonsClass(): string {
        return this.props.hasThreeSixtyThumbnails
            ? ['zoom_buttons'].join(' ')
            : ['zoom_buttons', 'tdi_full_width'].join(' ');
    }

    private threeDImagesClass(): string {
        const tdiClassName = this.props.hasThreeSixtyThumbnails ? '' : 'tdi_full_width';

        return this.state.animationIsLoaded
            ? [tdiClassName, 'three_d_images', 'show_td_images'].join(' ')
            : [tdiClassName, 'three_d_images'].join(' ');
    }

    private threeDImageClass(renderableImageIndex: number): string {
        return this.state.imageIndex == renderableImageIndex || this.props.imageUrlArray.length == 1
            ? ['three_d_image', 'active_image'].join(' ') : 'three_d_image';
    } 

    private zoomOutBtnStyleClass(): string {
        return this.state.zoomInLevel === minZoomLevel ? 'zoom_out_btn inactive' : 'zoom_out_btn';
    }

    private zoomInBtnStyleClass(): string {
        return this.state.zoomInLevel === maxZoomLevel ? 'zoom_in_btn inactive' : 'zoom_in_btn';
    }

    private onPinchingStart(ref: ReactZoomPanPinchRef): void {
        if(ref.state.scale === 1) {
            ref.instance.setup.panning.disabled = false;
            this.playStopAnimation(false);
            this.setState({imageMovementIsEnabled: true});
        }

        this.activeImageContainer = this.animationRef.current.querySelector('.active_image');
    }

    private onPinchingStop(ref: ReactZoomPanPinchRef): void {
        if(ref.state.scale <= 1) {
            ref.instance.setup.panning.disabled = true;
            this.resetDefaultImageSize();
        }
        else {
            this.setState({
                zoomInLevel: ref.state.scale,
                zoomedImageHeight: Math.round(this.state.currentImageHeight * ref.state.scale),
                zoomedImageIndex: parseInt(this.activeImageContainer.dataset.index)
            });
        }
    }

    private handleOnInit(ref: ReactZoomPanPinchRef): void {
        this.reactZoomPanPinchRef = ref;
        ref.instance.setup.panning.disabled = true;
        ref.instance.setup.doubleClick.disabled = true;
    }

    private onEnterViewport(): void {
        if (!this.animationRef.current) {
            return;
        } else {
            const top = this.animationRef.current.getBoundingClientRect().top;
            const viewportReached = top + this.offset >= 0 && top - this.offset <= window.innerHeight;

            if (viewportReached && top > 0) {
                this.setState({
                    loadContentAfterPageLoad: true,
                    loadAnimation: true
                });

                document.removeEventListener('scroll', this.onEnterViewport, true);
            }
        }
    }

    public render() {
        return (
            <div className={'three_sixty_animation'} ref={this.animationRef}
                data-testid={'three_sixty_animation'}>
                {
                    (helper.isMobileShop() && this.props.showOverlayAnimation) ?
                        <TransformWrapper minScale={1} maxScale={4}
                            onInit={this.handleOnInit}
                            onPinchingStart={this.onPinchingStart}
                            onPinchingStop={this.onPinchingStop}>
                            <TransformComponent>
                                {this.renderElementsForThreeSixtyView()}
                            </TransformComponent>
                        </TransformWrapper>
                        : 
                        this.renderElementsForThreeSixtyView()
                }
                <LoadingSpinner showLoadingSpinner={!this.state.animationIsLoaded} />
                {(this.props.showOverlayAnimation && !helper.isMobileShop()) &&
                    // only desktop devices has controls
                    <div className={this.zoomButtonsClass()}>
                        <div data-testid={'start_stop_button'} className={'start_stop_button'}
                            onClick={this.handleStartStopClick}><IconPlayStop /></div>
                        <div data-testid={'zoom_out_btn'} className={this.zoomOutBtnStyleClass()}
                            onClick={this.handleZoomOutClick}><IconZoomOut /></div>
                        <div data-testid={'zoom_in_btn'} className={this.zoomInBtnStyleClass()}
                            onClick={this.handleZoomInClick}><IconZoomIn /></div>
                    </div>
                }
            </div>
        );
    }
}
