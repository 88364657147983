/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
import React from 'react';

const IconZoomOut = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55">
        <path className="border" d="M27.5,1A26.5,26.5,0,1,1,1,27.5,26.53,26.53,0,0,1,27.5,1m0-1A27.5,27.5,0,1,0,55,27.5,27.50005,27.50005,0,0,0,27.5,0Z"/><path d="M37.5,28.40039h-20a.90039.90039,0,0,1,0-1.80078h20a.90039.90039,0,0,1,0,1.80078Z"/></svg>
);

export default IconZoomOut;
