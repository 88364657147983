import defaultStyles from '../../styles/defaults.scss';
import {isTablet} from 'react-device-detect';

export function isMobileShop(): boolean {
    if (typeof document !== 'undefined')
        return document.getElementsByTagName('html')[0].classList.contains('mobileshop');
    else
        return false;
}

export function isTabletView(): boolean {
    return isTablet;
}

export function isDeskTopView(): boolean {
    const width = parseInt(defaultStyles.breakpointDesktopMinWidth);
    return window.innerWidth >= width;
}

export function decodeHTML(value: string): string {
    return value.replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
    });
}

export function isKioskPc(): boolean {
    if (typeof document !== 'undefined')
        return document.getElementsByTagName('html')[0].classList.contains('kiosk');
    else
        return false;
}
