export class SCSScrollLock {
    private static _instance: SCSScrollLock;
    private bodyScrollPosition = 0;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public lock(): void {
        const webContainer = document.querySelector('.container');
        const mobileContainer = document.querySelector('.page-container');
        this.bodyScrollPosition = window.scrollY;

        if (webContainer || mobileContainer)
            document.body.setAttribute(
                'style',
                'overflow: hidden !important; position: fixed !important; top: -' +
                    this.bodyScrollPosition +
                    'px;'
            );
    }

    public unlock(): void {
        const webContainer = document.querySelector('.container');
        const mobileContainer = document.querySelector('.page-container');

        if (webContainer || mobileContainer) {
            document.body.removeAttribute('style');
            window.scrollTo(0, this.bodyScrollPosition);
        }
    }
}
