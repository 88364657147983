import React from 'react';
import { ThreeSixtyViewDataController } from './components/common/threeSixtyViewDataController';
import ThreeSixtyView from './components/threeSixtyView/threeSixtyView';
import { IThreeSixtyViewProps } from './components/threeSixtyView/threeSixtyView.d';
import { createRoot } from 'react-dom/client';
import { IProductInfo } from './components/common/productInfo';

if (window && window.shell) {
    window.shell.subscribeTo('SCS.Init.ThreeSixtyAnimation',
        (payload) => {
            const placeHolder: HTMLElement = document.getElementById(payload.containerId);

            if (placeHolder) {
                loadDataAndRenderApp(payload.masterArticleNo, payload.colorCode,
                    payload.containerId, payload.fallBackImageUrl, placeHolder);
            }
        },
        'SCS.Init.ThreeSixtyAnimation');
}

function loadDataAndRenderApp(masterArticleNo: string, colorCode: number,
    containerId: string, fallBackImageUrl: string, placeHolder: HTMLElement) {
    const threeSixtyViewController: ThreeSixtyViewDataController = ThreeSixtyViewDataController.instance;

    threeSixtyViewController.getThreeSixtyViewData(colorCode, masterArticleNo, '/')
        .then((threeSixtyView) => {
                renderApp(threeSixtyView, containerId, fallBackImageUrl, placeHolder);
        });
}

function getProductInfo(dataset): IProductInfo { // for Accessibility tags
    const categoryName = dataset.categoryname ? dataset.categoryname : '';
    const designation = dataset.designation ? dataset.designation : '';
    const colorName = dataset.colorname ? dataset.colorname : '';

    return {categoryName: categoryName, 
        designation: designation, 
        colorName: colorName,
        l10n: {localizationKey: '$$$LocalizationProductPresentation.Image.Showcase$$$'}};
}

function renderApp(threeSixtyView: IThreeSixtyViewProps,
    containerId: string, fallBackImageUrl: string, 
    placeHolder: HTMLElement) {    
    const root = createRoot(placeHolder);
    root.render(
        <div className='showcase-container'>
            <ThreeSixtyView
                threeSixty={threeSixtyView.threeSixty}
                threeSixtyThumbnails={null}
                generalData={threeSixtyView.generalData}
                masterArticleNo={threeSixtyView.masterArticleNo}
                colorCode={threeSixtyView.colorCode}
                containerId={containerId}
                loadContentAfterPageLoad={placeHolder.dataset.runafterpageload ?
                    JSON.parse(placeHolder.dataset.runafterpageload) : true}
                loadContentAfterPageLoadMobile={placeHolder.dataset.runafterpageloadmobile ?
                    JSON.parse(placeHolder.dataset.runafterpageloadmobile) : false}
                doNotUpdateAfterVariantChange={placeHolder.dataset.donotupdateaftervariantchange ?
                    JSON.parse(placeHolder.dataset.donotupdateaftervariantchange) : false}
                fallBackImageUrl={fallBackImageUrl}
                showOverlayAnimation={placeHolder.dataset.showoverlayanimation ?
                    JSON.parse(placeHolder.dataset.showoverlayanimation) : false}
                preventAutoPlay={placeHolder.dataset.preventautoplay ?
                    JSON.parse(placeHolder.dataset.preventautoplay) : false}
                productInfo={getProductInfo(placeHolder.dataset)}
            />
        </div>);
}
