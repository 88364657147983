import React from 'react';
import { ILoadingSpinnerProps, ILoadingSpinnerState } from './loadingSpinner.d';
import './loadingSpinner.scss';

export default class LoadingSpinner extends React.Component<ILoadingSpinnerProps,
    ILoadingSpinnerState> {

    constructor(props) {
        super(props);

        this.state = {
            showLoadingSpinner: this.props.showLoadingSpinner
        };
    }

    private loadingSpinnerClass(): string {
        return this.props.showLoadingSpinner
            ? ['loading_spinner', 'show_loading_spinner'].join(' ')
            : 'loading_spinner';
    }

    public render() {
        return (
            <div className={this.loadingSpinnerClass()}>
                <div className={'scs_loader'} />
            </div>
        );
    }
}
