import { IThreeSixtyViewProps } from '../threeSixtyView/threeSixtyView.d';
import { Fetch } from './Fetch';

export class ThreeSixtyViewDataController {
    private static _instance: ThreeSixtyViewDataController;
    private readonly fetch: Fetch;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    constructor() {
        this.fetch = new Fetch();
    }
    
    public getThreeSixtyViewData(colorCode: number, masterArticleNo: string, 
        originalRootPath: string, showCase: string = null): Promise<IThreeSixtyViewProps> {
        const urlToHost = window.location.protocol + '//' + window.location.host;

        const apiUrl = urlToHost
            + originalRootPath
            + 'api/showcase/getthreesixtyviewdata';

        return this.fetch.fetch(apiUrl, masterArticleNo, colorCode.toString(), showCase)
            .then((response) => {
                if (response) {
                    return response;
                }
                else {
                    // ping PDP and hide SCS fragment, 
                    // because selected does not have 3D animation
                    window.shell.publishTo('SCS.Hide.Fragment', true);
                    return undefined;
                }
            });
    }
}
