/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
import React from 'react';

const IconPlayStop = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="54.99998" viewBox="0 0 55 54.99998">
        <path className="border" d="M27.5,1A26.5,26.5,0,1,1,1,27.5,26.53,26.53,0,0,1,27.5,1m0-1A27.5,27.5,0,1,0,55,27.5,27.5,27.5,0,0,0,27.5,0Z"/><path d="M27.49951,18.7c-7.67773,0-13.69238,3.12011-13.69238,7.10351,0,3.90973,5.8,6.98065,13.27539,7.09247l-2.09961,2.09991a.8.8,0,0,0,1.13086,1.13184l3.5-3.5c.0061-.0061.00793-.01446.01379-.02075a3.07657,3.07657,0,0,0,.16577-.26343.81257.81257,0,0,0,.02283-.11694.79564.79564,0,0,0,.0271-.19019c-.00024-.00732.00342-.01361.00293-.021a.71888.71888,0,0,0-.02588-.09626.49633.49633,0,0,0-.12024-.29327.76318.76318,0,0,0-.0863-.13l-3.5-3.5a.8.8,0,1,0-1.13086,1.13184l2.17065,2.171c-6.40124-.0874-11.74682-2.56531-11.74682-5.49518,0-2.9834,5.53808-5.5039,12.09277-5.5039,6.55567,0,12.09375,2.5205,12.09375,5.5039,0,1.4209-1.334,2.84033-3.66015,3.895a.7998.7998,0,1,0,.66015,1.457c2.96582-1.34424,4.59961-3.24511,4.59961-5.352C41.19287,21.82008,35.17822,18.7,27.49951,18.7Z"/></svg>
);

export default IconPlayStop;
