import React from 'react';

const ArrowUp = () => (
    <svg
        viewBox="0 4 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M14.3538 11.354C14.3073 11.4005 14.2522 11.4375 14.1914 11.4627C14.1307 11.4879 14.0655 11.5009 13.9998 11.5009C13.934 11.5009 13.8689 11.4879 13.8081 11.4627C13.7474 11.4375 13.6922 11.4005 13.6458 11.354L7.99978 5.70696L2.35378 11.354C2.30729 11.4004 2.2521 11.4373 2.19136 11.4625C2.13062 11.4876 2.06552 11.5006 1.99978 11.5006C1.93403 11.5006 1.86893 11.4876 1.80819 11.4625C1.74745 11.4373 1.69227 11.4004 1.64578 11.354C1.59929 11.3075 1.56241 11.2523 1.53725 11.1915C1.5121 11.1308 1.49915 11.0657 1.49915 11C1.49915 10.9342 1.5121 10.8691 1.53725 10.8084C1.56241 10.7476 1.59929 10.6924 1.64578 10.646L7.64578 4.64596C7.69222 4.5994 7.7474 4.56246 7.80814 4.53725C7.86889 4.51204 7.93401 4.49907 7.99978 4.49907C8.06555 4.49907 8.13067 4.51204 8.19141 4.53725C8.25216 4.56246 8.30733 4.5994 8.35378 4.64596L14.3538 10.646C14.4003 10.6924 14.4373 10.7476 14.4625 10.8083C14.4877 10.8691 14.5007 10.9342 14.5007 11C14.5007 11.0657 14.4877 11.1308 14.4625 11.1916C14.4373 11.2523 14.4003 11.3075 14.3538 11.354V11.354Z" fill="black" />
    </svg>
);

export default ArrowUp;
