import axios, { AxiosError, AxiosResponse } from 'axios';

export class Fetch {
    public fetch(apiUrl: string, masterArticleNo: string, colorCode: string, showCase: string) {
        const requestUrl = new URL(apiUrl);
        requestUrl.searchParams.set('masterArticleNo', masterArticleNo);
        requestUrl.searchParams.set('colorCode', colorCode);

        if(showCase)
            requestUrl.searchParams.set('showCase', showCase);

        return axios.get(requestUrl.toString())
            .then((response: AxiosResponse) => response.status === 200 ? response.data : undefined)
            .catch((error: Error | AxiosError) => {
                const errorMsg = this.isAxiosError(error)
                    ? error.message
                    : `${error.name}: ${error.message}`;
                const errorStack = this.isAxiosError(error)
                    ? JSON.stringify(error.toJSON())
                    : error.stack;

                // eslint-disable-next-line no-console
                console.warn(
                    `fetch() failed with "${errorMsg}". Full error:\n${errorStack}`
                );
            });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isAxiosError(error: any): error is AxiosError {
        return typeof error.toJSON !== 'undefined';
    }
}
