import { IGeneralData } from './generalData';
import * as helper from './scs-html-helper';

export class ImageController {
    private static _instance: ImageController;

    // This const affects rotation sensitivity dragging with mouse, higher value make's it less sensitive.
    private readonly pixelsPerDegree = 3;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public getRotatedImageIndex(currentPositionX: number, animationSequenceLength: number,
        dragStartScreenX: number, dragStartImageIndex: number): number {
        let result = 0;
        
        const numImages = animationSequenceLength;
        const pixelsPerImage = this.pixelsPerDegree * (50 / numImages);

        // Pixels moved:
        const dx: number = (currentPositionX - dragStartScreenX) / pixelsPerImage;
        let index = Math.floor(dx) % numImages;

        // Setting rotation direction: +- x
        index = numImages - index;

        // Calculate real index in the boundaries of 3d images length:
        result = (index + dragStartImageIndex) % numImages;

        return result;
    }

    public getImageHeight(generalData: IGeneralData): number {
        let newImageHeight;
        let currentWindowWidth = helper.isMobileShop() ? window.outerWidth : window.innerWidth;
        
        // for zoom in browser feature
        if(window.devicePixelRatio > 1 && !helper.isMobileShop() && !helper.isTabletView())
            currentWindowWidth = currentWindowWidth / window.devicePixelRatio;

        if (currentWindowWidth < generalData.breakPointMobile) {
            newImageHeight = generalData.imageHeightMobile;
        }
        else if (currentWindowWidth < generalData.breakPointTablet) {
            newImageHeight = generalData.imageHeightTablet;
        }
        else if (currentWindowWidth < generalData.breakPointDesktopMin) {
            newImageHeight = generalData.imageHeightDesktopMin;
        }
        else {
            // greater then 1200
            newImageHeight = generalData.imageHeightDesktopMax;
        }

        return newImageHeight;
    }
}
