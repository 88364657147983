/* eslint-disable max-len */
import React from 'react';

const Icon_Close_Large = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55">
        <path className="border" d="M27.5,1A26.5,26.5,0,1,1,1,27.5,26.53007,26.53007,0,0,1,27.5,1m0-1A27.5,27.5,0,1,0,55,27.5,27.49989,27.49989,0,0,0,27.5,0Z" />
        <path d="M28.773,27.49976l8.36328-8.36329A.90011.90011,0,1,0,35.86383,17.863l-8.36377,8.36377L19.13629,17.863a.90011.90011,0,1,0-1.27246,1.27343l8.36328,8.36329L17.86383,35.863a.90011.90011,0,1,0,1.27246,1.27343l8.36377-8.36376,8.36377,8.36376A.90011.90011,0,1,0,37.13629,35.863Z" />
    </svg>
);

export default Icon_Close_Large;
